<!--
 * @Description: 
 * @Version: 
 * @Autor: houchuanfang
 * @Date: 2022-10-21 16:15:01
 * @LastEditTime: 2023-07-03 09:38:57
-->
<template>
  <el-dialog title="授权注册绑定BOSS直聘账户" custom-class="bind-boss-dialog" :visible.sync="config.show" width="558px" center>
    <div class="con">
      <h3>授权注册并绑定BOSS直聘账户后，可在线进行简历投递</h3>
      <div class="logos">
        <img class="logo" src="~/static/job/boss1.png" alt="">
        <img class="arrow" src="~/static/job/arrow.png" alt="">
        <img class="logo" src="~/static/job/logo.png" alt="">
      </div>
      <div class="verify-con">
        <p>授权账号：{{isLogin.linkphone}}</p>
        <el-input v-model.trim="bossAuthCode" placeholder="请输入短信验证码">
          <template slot="append">
            <el-divider direction="vertical"></el-divider><span @click="sendMsg"> {{ time.btntxt }}</span>
          </template>
        </el-input>
      </div>
      <div class="protocol">
        <el-checkbox v-model="isprot" style="white-space: normal;display:flex">
          <div>
            同意
            <a href="https://about.zhipin.com/agreement?id=registerprotocol" target="_blank">《BOSS直聘用户协议》</a>
            <a href="https://about.zhipin.com/agreement?id=personalinfopro" target="_blank">《BOSS直聘隐私政策》</a>
            <a href="https://www.cdpee.org.cn/protocol/userServe/" target="_blank">《用户服务协议》</a>
            <a href="https://www.cdpee.org.cn/protocol/agreement/" target="_blank">《隐私政策》</a>
            <a href="https://about.zhipin.com/agreement?id=43a04522d556476fa93b80db4671a849" target="_blank">《无障碍求职服务授权协议》</a>
          </div>
        </el-checkbox>
      </div>
      <div class="des">
        <p class="text">{{config.type == 1 ?'此岗位来自BOSS直聘，' :''}}如您点击授权，我们将向BOSS直聘共享您的下列个人信息，以向您提供求职服务：</p>
        <ul>
          <li><i></i>在线简历信息</li>
          <li><i></i>手机号</li>
          <li><i></i>残疾状态信息</li>
        </ul>
        <p class="tips">授权后，将根据上述授权信息自动帮您完善与更新BOSS直聘内的简历，<strong>并使用上述全部信息为您生成附件简历</strong>，以便您后续向招聘者投递。同时，您同意BOSS直聘将其平台上的求职流程进展同步至中国残疾人就创业网络服务平台。</p>
      </div>

    </div>
    <div slot="footer" class="dialog-footer">
      <el-button round @click="config.show = false">取消授权</el-button>
      <el-button type="primary" round @click="onbind">授权并绑定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    config: {
      type: Object,
      default: {
        show: false
      }
    }
  },
  data() {
    return {
      isprot: false,
      bossAuthCode: '',
      time: {
        time: 180,
        btntxt: '获取验证码',
        getCodeBtnDisable: false
      },
      isLogin: {}
    };
  },
  methods: {
    onbind() {
      let { bossAuthCode, isprot } = this;
      if (!bossAuthCode) {
        this.$message.error('请输入验证码');
        return;
      }
      if (!isprot) {
        this.$message.error('请您勾选协议');
        return;
      }
      this.$api.bindBossApi({ bossAuthCode: bossAuthCode }).then(res => {
        res = res.data;
        if (res.success) {
          this.config.onconfirm && this.config.onconfirm();
          this.config.show = false;
        } else {
          this.$message.error(res.msg || '系统错误');
        }
      });
    },
    sendMsg() {
      //倒计时
      let time = this.time;
      let timer = () => {
        let time = this.time;
        if (time.time > 0) {
          time.time--;
          time.btntxt = time.time + 's后重新获取';
          setTimeout(timer, 1000);
        } else {
          time.time = 0;
          time.btntxt = '获取验证码';
          time.getCodeBtnDisable = false;
        }
      };
      if (time.getCodeBtnDisable) return;
      time.getCodeBtnDisable = true;
      time.btntxt = '发送中...';
      this.$api.getBossAuthCode().then(res => {
        let { success, msg } = res.data;
        if (!success && msg) {
          this.$message.error(msg || '发送失败');
          time.getCodeBtnDisable = false;
          time.btntxt = '获取验证码';
        } else {
          this.$message.success('发送成功！');
          time.time = 180;
          timer();
        }
      });
    }
  },
  mounted() {
   this.isLogin = this.$store.state.userType || JSON.parse(localStorage.getItem('userType')) || {};
    let {linkphone} = this.isLogin
    this.isLogin.linkphone = linkphone ? linkphone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2') : '';
  }
};
</script>
<style lang='less' scoped>
:deep(.bind-boss-dialog) {
  border-radius: 8px;
  .el-dialog__title {
    font-size: 22px;
    font-weight: 600;
    font-size: 20px;
    color: #1f2924;
  }
  .el-dialog__body {
    padding-bottom: 10px;
    padding-top: 0;
    padding: 0px 22px 30px;
  }
  .el-dialog__header {
    text-align: left;
    padding-left: 22px;
  }
  .con {
    // padding: 0 30px;
    h3 {
      font-size: 16px;
      color: #4b534f;
      line-height: 24px;
      font-weight: normal;
    }
    .logos {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 54px 0;
      img.logo {
        width: 80px;
        height: 80px;
        background-color: #eee;
        border-radius: 50%;
      }
      img.arrow {
        width: 66px;
        margin: 0 15px;
      }
    }
    .des {
      background: #f8f8f8;
      border-radius: 8px;
      border: 1px solid rgba(75, 83, 79, 0.1);
      color: #00120a;
      text-align: left;
      padding: 16px ;
      font-size: 16px;
      .text {
        font-size: 14px;
        color: #4b534f;
        line-height: 22px;
      }
      .tips {
        font-size: 14px;
        color: #787e7b;
        line-height: 22px;
        border-top: 1px solid rgba(31,41,36,0.1);;
        padding-top: 6px;
      }
      ul {
        margin-bottom: 10px;
        margin-top: 8px;
        li {
          display: inline-block;
          font-size: 14px;
          color: #4b534f;
          line-height: 30px;
          background: #ededed;
          border-radius: 4px;
          padding: 0 8px;
          border: 1px solid rgba(31, 41, 36, 0.1);
          margin-right: 24px;
          i {
            display: inline-block;
            width: 2px;
            height: 2px;
            background: rgba(102, 113, 108, 1);
            border-radius: 50%;
            margin-right: 8px;
            vertical-align: middle;
          }
        }
      }
    }
    .protocol {
      text-align: left;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 10px;
      .el-checkbox__label {
        margin-top: -3px;
      }
      a {
        // color: #00924b;
      }
    }
    .verify-con {
      width: 384px;
      margin: 24px auto 0;
      p {
        font-size: 16px;
        color: rgba(120, 126, 123, 1);
        line-height: 24px;
        margin-bottom: 8px;
      }
      .el-input {
        .el-input__inner {
          border-color: rgba(0, 18, 10, 0.05);
          background-color: rgba(0, 18, 10, 0.02);
          height: 48px;
          line-height: 48px;
          border-right: none;
        }
      }
      .el-input-group__append {
        padding: 0;
        padding-right: 20px;
        border-color: rgba(0, 18, 10, 0.05);
        background-color: rgba(0, 18, 10, 0.02);
        span {
          margin-left: 6px;
          cursor: pointer;
          color: #66716c;
          transition: all 0.3s;
          &:hover {
            color: #00924b;
          }
        }
      }
      // --el-input-border-color: rgba(0, 18, 10, 0.05);
      // --el-fill-color-blank: rgba(0, 18, 10, 0.02);
    }
  }
  .dialog-footer {
    button {
      width: auto !important;
      & + button{
            background: rgb(2, 190, 189);
    border: 1px solid rgb(2, 190, 189);
      }
      span {
        font-size: 16px;
      }
    }
  }
}
</style>
