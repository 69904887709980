<template>
  <div class="setting world">
    <div class="title backgroundWhite marB10">
      <h1 class="h1">绑定账号</h1>
    </div>
    <div class="setting_content backgroundWhite">
      <div class="tip gray">
        <p>注：绑定后，您可以通过以下方式登录平台</p>
      </div>
      <div class="content">
        <ul class="ul">
          <li>
            <div v-show="userList.weixin == 0" class="green">
              <a href="javascript:void(0);" @click="setKey">
                <img src="~/static/login/wechat.png" alt="">
                绑定微信账号
              </a>
            </div>
            <div v-show="userList.weixin == 1">
              <img src="~/static/login/wechat_color.png" alt="">
              已绑定账号:{{userInfo.userName}} <span @click="noPay('weixin')">解除绑定</span>
            </div>
          </li>
          <li>
            <div v-show="userList.zhifubao == 0" class="green">
              <a
                  :href="'https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021002191647409&scope=auth_base&redirect_uri='+encodeURIComponent('https://www.cdpee.org.cn/api/app-jycy-member/zhiBaoLogin')+'&state=5,'+openUrl+'/settingUser/,'+userInfo.token">
                <img src="~/static/login/pay.png" alt="">
                绑定支付宝账号
              </a>
            </div>
            <div v-show="userList.zhifubao == 1">
              <img src="~/static/login/pay_color.png" alt="">
              已绑定账号:{{ userInfo.userName }} <span @click="noPay('zhifubao')">解除绑定</span>
            </div>
          </li>
          <li>
            <div v-show="userList.boss == 0" class="green">
              <a href="javascript:;" @click="bindBossConfig.show = true">
                <img style="    filter: grayscale(85%);" src="~/static/job/logo2.png" alt="">
                绑定boss账号
              </a>
            </div>
            <div v-show="userList.boss != 0">
              <img src="~/static/job/logo2.png" alt="">
              已绑定账号:{{ userInfo.userName }}
              <span @click="noPay('boss')">解除绑定</span>
            </div>
          </li>
          <li v-if="false">
            <div v-show="userList.qq == 0" class="green">
              <a
                  :href="'https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=101981890&redirect_uri='+encodeURIComponent('https://www.cdpee.org.cn/api/app-jycy-member/getTokenByCode')+'&state='+openUrl+'/settingUser/,'+userInfo.token">
                <img src="~/static/login/qq.png" alt="">
                绑定QQ账号
              </a>
            </div>
            <div v-show="userList.qq == 1">
              <img src="~/static/login/qq_color.png" alt="">
              已绑定账号:{{ userInfo.userName }}
              <span @click="noPay('qq')">解除绑定</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <el-dialog title="扫码绑定微信" :visible.sync="dialogVisible" width="290px">
      <img :src="src" alt="" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <bindBoss :config="bindBossConfig" />

  </div>
</template>

<script>
import bindBoss from '@/components/dialog/bindBossDialog.vue';
export default {
  name: 'settingEmail',
  components: {
    bindBoss
  },
  data() {
    return {
      active: [0, 1, 1],
      userList: [],
      userInfo: [],
      openUrl: 'https://www.cdpee.org.cn',
      loginType: '',
      uuid: '',
      key: '0',
      src: '',
      dialogVisible: false,
      bindBossDialog: false,
      bindBossConfig: {
        show: false,
        onconfirm: this.info
      }
    };
  },
  methods: {
    // 查询当前绑定账号
    async info() {
      let res = await this.$api.getListOtherLoginApi();
      if (res.data.success) {
        this.userList = res.data.data;
      }
      if (localStorage.getItem('userInfo')) {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      }
    },
    //解除绑定
    async noPay(name) {
      if (name == 'boss') {
        this.$confirm(
            '已投递的行为仍然有效，但您可能无法查看招聘流程进展，如您希望投递来自BOSS直聘的岗位时，需要重新绑定，请您谨慎解绑。',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }
        )
            .then(() => {
              this.unbundle(name);
            })
            .catch(() => {});
      } else {
        this.unbundle(name);
      }
    },
    unbundle(name) {
      this.$api
          .removeOtherLoginApi({
            loginType: name
          })
          .then(res => {
            if (res.data.success) {
              this.$message.success('解除绑定成功！');
              this.info();
            }
          });
    },
    //  获取uuid和key
    getKey() {
      this.$axios.get('/api/app-jycy-member/getAbTextKey').then(res => {
        this.uuid = res.data.data.uuid;
        this.key = res.data.data.key;
      });
    },
    // 向后台发送UUID，key，点击位置
    setKey() {
      this.$axios
          .post('/api/app-jycy-member/setAbTextKey', {
            uuid: this.uuid,
            key: this.key,
            pointKey: this.pointKey
          })
          .then(res => {
            this.login();
          });
    },
    // 绑定微信，赋值图片src属性
    login(argument) {
      this.$axios
          .get('/api/app-jycy-wechat/wx/loginStart?key=' + this.uuid)
          .then(res => {
            this.src = res.data.data.url;
            this.dialogVisible = true;
            this.code = res.data.data.code;
          });
      this._timeInterval = setInterval(() => {
        this.checkLogin();
      }, 3000);
    },
    checkLogin() {
      this.$axios
          .get('/api/app-jycy-wechat/wx/loginStatusCheck?key=' + this.code)
          .then(res => {
            if (res.data.success) {
              window.clearInterval(this._timeInterval);
              this.dialogVisible = false;
              this.info();
              this.$message({
                message: '綁定成功',
                type: 'success'
              });
            }
          });
    }
  },
  created() {
    this.info();
    this.getKey();
  }
};
</script>

<style scoped lang="less">
@import '~assets/css/setting.less';

:deep(.el-input-group__append) {
  background: #ffffff;
  color: #00924c;
}

.ul {
  li {
    margin-bottom: 20px;

    div {
      display: flex;

      a {
        display: flex;
      }

      img {
        width: 28px;
        transform: translateY(-2px);
        margin-right: 10px;
      }

      color: #666666;
    }
    span {
      color: #007aff;
      padding-left: 20px;
      cursor: pointer;
    }
    .green {
      a {
        color: #00924b;
      }
    }
  }
}

.world {
  background: #ffffff;
  margin: 0;
  padding: 30px 20px !important;
}
:deep(.el-dialog){
  img {
    width: 200px;
  }
  .el-dialog__body {
    text-align: center;
  }
}
</style>
